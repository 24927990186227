
import { defineComponent } from "vue";
import Container from "../../components/Structure/Container.vue";
import {
  Header,
  SingleCheckbox,
  QuestionWrapper,
} from "../../components/Questions";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Container,
    Header,
    SingleCheckbox,
    QuestionWrapper,
  },
  setup() {
    const questionNumber = 7;
    const router = useRouter();

    const title = "Do you smoke?";
    const subtitle =
      "Smoking while on birth control will increase the risk of heart attacks.";
    const options = [
      {
        label: "No, I don't smoke",
        value: "No, I don't smoke",
      },
      {
        label: "Not anymore, but I used to smoke",
        value: "Not anymore, but I used to smoke",
      },
      {
        label: "Yes, I smoke less than 15 cigarettes a day on average",
        value: "Yes, I smoke less than 15 cigarettes a day on average",
      },
      {
        label: "Yes, I smoke more than 15 cigarettes a day on average",
        value: "Yes, I smoke more than 15 cigarettes a day on average",
      },
    ];

    const onAnswered = () => {
      router.push(String(questionNumber + 1));
    };

    return {
      options,
      onAnswered,
      title,
      subtitle,
      questionNumber,
    };
  },
});
